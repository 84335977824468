<template>
  <div class="calculate-container">
    <div class="tab-header">
      <h4>정산 내역</h4>
      <div class="filter-month">
        <button
          :disabled="state.getLoading || state.prevYearDisabled"
          @click="actions.getMonthSales('prev')"
        >
          <arrow-icon
            direction="left"
            stroke-size="normal"
            :fill-color="state.prevYearDisabled ? '#D0D5DE' : '#0D0D10'"
          ></arrow-icon>
        </button>

        <span class="month sub-title-s1">{{ state.filterYearForUI }}</span>

        <button
          :disabled="state.getLoading || state.nextYearDisabled"
          @click="actions.getMonthSales('next')"
        >
          <arrow-icon
            direction="right"
            stroke-size="normal"
            :fill-color="state.nextYearDisabled ? '#D0D5DE' : '#0D0D10'"
          ></arrow-icon>
        </button>
      </div>
    </div>

    <div class="table-header">
      <span class="col1 sub-text-s3 text-gray-second">정산 ID</span>
      <span class="col2 sub-text-s3 text-gray-second">정산 등록일</span>
      <span class="col3 sub-text-s3 text-gray-second">정산 상태</span>
      <span class="col4 sub-text-s3 text-gray-second">판매 금액(VAT 포함)</span>
      <span class="col5 sub-text-s3 text-gray-second">실수령 금액</span>
      <span class="col6 sub-text-s3 text-gray-second">정산 지급일</span>
    </div>

    <ul v-if="!state.emptyList" class="calculate-list-wrapper">
      <li
        v-for="settlement in state.settlements"
        :key="`settlement-item-${settlement.resourceId}`"
      >
        <calculate-item :settlement="settlement"></calculate-item>
      </li>
    </ul>

    <empty-list
      v-if="state.emptyList"
      icon-image="/assets/images/settlement/calc_empty.png"
      text="정산된 내역이 없습니다."
    ></empty-list>

    <div
      v-if="state.settlementsMeta.pagination.lastPage > 1"
      class="pagination-wrapper"
    >
      <pagination
        :pagination="state.settlementsMeta.pagination"
        @getPageData="(page) => getSettlements({ page: page })"
      ></pagination>
    </div>
  </div>
</template>

<script>
import ArrowIcon from "../../../../components/console/icons/ArrowIcon";
import { computed, onBeforeMount, reactive } from "vue";
import moment from "moment-timezone";
import { useStore } from "vuex";
import CalculateItem from "../CalculateItem/CalculateItem";
import EmptyList from "../../../../components/console/empties/EmptyList";
import Pagination from "../../../../components/console/pagination/Pagination";

export default {
  name: "TabCalculate",
  components: { Pagination, EmptyList, CalculateItem, ArrowIcon },
  setup() {
    const store = useStore();
    const yearFormat = "YYYY";

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      settlements: computed(() => {
        return store.getters["settlements/settlements"];
      }),
      settlementsMeta: computed(() => {
        return store.getters["settlements/settlementsMeta"];
      }),
      emptyList: computed(() => {
        return state.settlements.length === 0;
      }),
      getLoading: false,
      locale: computed(() => {
        return store.getters["locale/locale"];
      }),
      filterYear: "",
      filterYearForUI: computed(() => {
        return `${state.filterYear}년`;
      }),
      filterIndex: computed(() => {
        return store.getters["settlements/filterYearIndex"];
      }),
      nextYearDisabled: computed(() => {
        return state.filterYear === moment().format(yearFormat);
      }),
      prevYearDisabled: computed(() => {
        return (
          moment(state.user.createdAt).format(yearFormat) === state.filterYear
        );
      }),
    });

    onBeforeMount(() => {
      setFilterYear();
      getSettlements({ year: state.filterYear });
    });

    const getSettlements = (data) => {
      state.getLoading = true;
      let payload = data
        ? {
            year: data.year ? data.year : null,
            page: data.page ? data.page : 1,
          }
        : null;

      store.dispatch("settlements/getSettlements", payload).then(() => {
        state.getLoading = false;
      });
    };

    const setFilterYear = () => {
      state.filterYear = moment()
        .add(state.filterIndex, "years")
        .format(yearFormat);
    };

    const actions = {
      getMonthSales: (direction) => {
        if (!state.getLoading) {
          if (direction === "prev") {
            store.dispatch("settlements/updateYearIndex", { direction: -1 });
          } else {
            store.dispatch("settlements/updateYearIndex", { direction: 1 });
          }

          setFilterYear();
          getSettlements({ year: state.filterYear });
        }
      },
    };

    return { state, actions, getSettlements };
  },
};
</script>

<style src="./style.css" scoped></style>
