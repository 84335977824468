<template>
  <div
    :class="{
      'status-success': settlement.status === settlementStatus.complete.value,
      'status-waiting': settlement.status === settlementStatus.wait.value,
      'status-fail': settlement.status === settlementStatus.fail.value,
    }"
    @click="actions.goToCalculateDetail()"
  >
    <div class="calculate-item-mo">
      <card-basic>
        <h4>{{ settlement.resourceId }}</h4>
        <ul class="sales-contents">
          <li>
            <span class="b-text-3 text-gray-second">정산 등록일</span>
            <span class="b-text-1">{{ state.createdAt }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">정산 상태</span>
            <span class="b-text-1 status">{{ state.status }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">판매 금액(VAT 포함)</span>
            <span class="b-text-1">{{ state.amount }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">실수령 금액</span>
            <span> {{ state.payout }}</span>
          </li>
          <li>
            <span class="b-text-3 text-gray-second">정산 지급일</span>
            <span class="b-text-1">{{ state.paidAt }}</span>
          </li>
        </ul>
      </card-basic>
    </div>

    <div class="calculate-item-pc">
      <div class="calculate-item">
        <span class="col1 b-text-2">{{ settlement.resourceId }}</span>
        <span class="col2 b-text-2">{{ state.createdAt }}</span>
        <span class="col3 b-text-2 status">
          {{ state.status }}
        </span>
        <span class="col4 b-text-2"> {{ state.amount }} </span>
        <span class="col5 b-text-2">{{ state.payout }}</span>
        <span class="col6 b-text-2">{{ state.paidAt }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CardBasic from "../../../../components/console/cards/CardBasic";
import { computed, getCurrentInstance, reactive } from "vue";
import router from "../../../../router";
import { dateFormat } from "@/helper/date";
import { settlementPriceFormat } from "../../../../helper/settlement";

export default {
  name: "CalculateItem",
  components: { CardBasic },
  props: {
    settlement: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const settlementStatus = proxy.$const.settlementStatus;

    const state = reactive({
      createdAt: computed(() => {
        return dateFormat(props.settlement.createdAt);
      }),
      status: computed(() => {
        switch (props.settlement.status) {
          case settlementStatus.fail.value:
            return settlementStatus.fail.text;
          case settlementStatus.complete.value:
            return settlementStatus.complete.text;
          default:
            return settlementStatus.wait.text;
        }
      }),

      amount: computed(() => {
        return settlementPriceFormat(
          props.settlement.currency,
          props.settlement.amount
        );
      }),
      payout: computed(() => {
        return props.settlement.payout
          ? settlementPriceFormat(
              props.settlement.currency,
              props.settlement.payout
            )
          : "-";
      }),
      paidAt: computed(() => {
        return props.settlement.paidAt
          ? dateFormat(props.settlement.paidAt)
          : "-";
      }),
    });

    const actions = {
      goToCalculateDetail: () => {
        router.push({
          name: "console.settlement.calculate.show",
          params: {
            settlementResourceId: props.settlement.resourceId,
          },
        });
      },
    };

    return { state, actions, settlementStatus };
  },
};
</script>

<style src="./style.css" scoped></style>
